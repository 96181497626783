import React, { useState } from "react";
import { Typography, Button } from "@mui/material";

const ReadMore = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Typography
        sx={{
          fontSize: 16,
          fontWeight: 400,
          textAlign: "justify",
          color: "gray",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "none" : 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
      <Button onClick={toggleReadMore} sx={{ textTransform: "none" }}>
        {isExpanded ? "Read Less" : "Read More"}
      </Button>
    </div>
  );
};

export default ReadMore;
