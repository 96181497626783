import React from "react";
import "./brokerage.css";

import AnimatedComponent from "./aniamtion";
import { InternPricing } from "./pricing/InternPricing";

export const BrokerageComp = () => {
  return (
    <>
      <AnimatedComponent />
      <InternPricing />
    </>
  );
};
