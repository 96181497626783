import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import homeBg from "../../assets/newProfessionals/homesBg.png";
import shades from "../../assets/newProfessionals/shades.png";
import sliderBg from "../../assets/newProfessionals/AdCards.png";
import circleBackground from "../../assets/newProfessionals/Background.svg";
import buyingCarousel from "../../assets/newProfessionals/image25.png";
import peopleTalking from "../../assets/newProfessionals/peopleBackground.png";
import homeBg2 from "../../assets/newProfessionals/bg2.svg";
import newBg from "../../assets/newProfessionals/NewNewBg.jpg";
import homeBg3 from "../../assets/newProfessionals/bg3.svg";
import homeBg4 from "../../assets/newProfessionals/bg4.svg";
import star from "../../assets/newProfessionals/start.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@emotion/react";
import { SearchComp } from "./SearchBar/Search";
import "./ProfessionalsHome.css";
import React, { useEffect, useState } from "react";
import base from "../../api/apis";
import { sampleSize } from "lodash";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

export const ProfessionalsHome = ({
  load,
  clickAway,
  onChange,
  focus,
  value,
  searchFilter,
  results,
  searchFocus,
  searchTerm,
  type,
  recent,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [featuredAgents, setFeaturedAgents] = useState(null);
  const navigate = useNavigate();

  // fetch featured agents
  useEffect(() => {
    (async () => {
      try {
        const { data: featured } = await base.get(
          "register/random_paid_agent/"
        );

        //sample featuredAgents
        setFeaturedAgents((state) => {
          const res = sampleSize(featured, 2);
          return res;
        });
      } catch (error) { }
    })();
  }, []);

  const routeToPage = (e, link) => {
    e.stopPropagation();
    navigate(link);
  };
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Grid
        container
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${homeBg})`,
            backgroundSize: isMobile ? "200% 100%" : "100% 100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: -1,
          },
          minHeight: "70vh",
        }}
      >
        <Grid
          className="left-slider"
          item
          md={4}
          sx={{
            position: "relative",
            display: { sm: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${shades})`,
              // backgroundSize: isMobile ? "100% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
            <Typography sx={{ color: "white", fontWeight: 700 }}>
              Industry Professionals{" "}
            </Typography>
            <Typography color={"primary"} sx={{ fontWeight: 700 }}>
              Need a Pro
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Attorneys
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Contractors
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Stagers
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Electricians
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Plumbers
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Roofers
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              &
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 500 }}>
              More!
            </Typography>
            <Button
              sx={{ textTransform: "none" }}
              href={`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}industry-professionals`}
              variant="contained"
            >
              {" "}
              Connect with Experts
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            className="text-search"
            sx={{
              color: "white",
              fontSize: "4em",
              fontWeight: "700",
              boxShadow: "initial",
            }}
          >
            Property Search
          </Typography>
          <Box width={"95%"} zIndex={30} className="search-box-animation">
            <SearchComp
              load={load}
              results={results}
              type={type}
              searchFilter={searchFilter}
              onChange={onChange}
              clickAway={clickAway}
              value={value}
              focus={focus}
              searchFocus={searchFocus}
              searchTerm={value}
              recent={recent}
            />
          </Box>
        </Grid>
        <Grid
          item
          className="right-slider"
          sx={{
            position: "relative",
            height: "70vh",
            display: { sm: "none", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${shades})`,
              backgroundSize: isMobile ? "100% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
            },
          }}
          md={4}
        >
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
            <Typography sx={{ color: "white", fontWeight: 700 }}>
              Agent Finder
            </Typography>
            <Typography color={"primary"} sx={{ fontWeight: 700 }}>
              Our Agents Provide
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              BPO Reports
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Proforma Reports
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Property Evaluations
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Property Tours
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Buyer Representation
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              Seller Representation
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 300 }}>
              &
            </Typography>
            <Typography color={"white"} sx={{ fontWeight: 500 }}>
              More!
            </Typography>
            <Button
              sx={{ textTransform: "none" }}
              onClick={(e) => routeToPage(e, `/agents`)}
              variant="contained"
            >
              Connect with a Trusted Agent
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${circleBackground})`,
            backgroundSize: isMobile ? "100% 100%" : "100% 120%",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: -1,
          },
          height: "40%",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            padding: "1rem",
            margin: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Grid item sm={10} md={3}>
              <Typography fontSize={"2rem"} fontWeight={"700"}>
                Ready to Buy?
              </Typography>
              <Typography fontSize={"2rem"} fontWeight={"700"}>
                Ready to Sell?
              </Typography>
              <Typography fontSize={"2rem"} fontWeight={"700"}>
                Analyze a Deal?
              </Typography>
              <Box
                sx={{
                  width: 100,
                  height: 3,
                  bgcolor: theme.palette.primary.main,
                  mt: 2,
                }}
              />
              <Typography mt={4} mb={8}>
                BPO Homes has been everything you
                <br />
                need
              </Typography>
              <button
                className="btn-grad"
                onClick={(e) => routeToPage(e, "/homes-for-sale")}
              >
                {" "}
                View more Homes Now!
                <ArrowForwardIcon />
              </button>
            </Grid> */}
            <Grid sm={10} width={"100%"} md={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Box sx={{ bgcolor: "#E6E6E6" }}>
        <Container maxWidth={"xl"}>
          <Grid
            gap={5}
            sx={{
              minHeight: "50vh",

              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            container
          >
            <Grid item sm={10} md={3}>
              <Box>
                <Typography fontSize={"2rem"} fontWeight={"700"}>
                  Need An Agent?
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 100,
                  height: 3,
                  bgcolor: theme.palette.primary.main,
                  mt: 5,
                }}
              />
              <Typography mt={1} mb={8}>
                BPO Homes has Top Agents in
                <br />
                your Area
              </Typography>
              <Button
                sx={{
                  transition: "1s ease-in-out",
                  textTransform: "none",
                  height: 45,
                }}
                className="btn-grad"
                onClick={(e) => routeToPage(e, "/agents")}
              >
                {" "}
                View more Homes Now!
                <ArrowForwardIcon />
              </Button>
            </Grid>
            <Grid
              item
              sm={10}
              md={4}
              display={"flex"}
              flexWrap={isMobile ? "wrap" : ""}
              sx={{ justifyContent: isMobile && "center" }}
              gap={2}
            >
              {featuredAgents?.map((agent) => (
                <Card
                  key={agent?.id}
                  sx={{
                    minWidth: isMobile ? 350 : 250,
                    maxWidth: isMobile ? 350 : 250,
                    height: 290,
                    display: "relative",
                  }}
                >
                  <CardContent>
                    <div
                      onClick={(e) => routeToPage(e, `/agents/${agent?.id}`)}
                    >
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Avatar
                          sx={{ width: 70, height: 70 }}
                          alt="Travis Howard"
                          src={agent?.profile_image}
                        />
                        <div>
                          <Typography color={"secondary"} fontWeight={500}>
                            {`${agent?.first_name} ${agent?.last_name}`}
                          </Typography>
                          <Typography fontSize={12}>
                            {agent?.agent_status}
                          </Typography>
                          <Typography>
                            5.0{" "}
                            <Rating
                              color="primary"
                              size="small"
                              value={5}
                              readOnly
                              precision={0.5}
                            />{" "}
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <Typography
                          color={"secondary"}
                          mt={2}
                          fontWeight={400}
                          fontSize={14}
                        >
                          Specialties
                        </Typography>
                        <Box
                          mb={4}
                          sx={{
                            display: "flex",
                            gap: 0.5,
                            flexWrap: "wrap",
                            height: 80,
                          }}
                        >
                          {agent?.specialties?.length === 0 ? (
                            <Typography
                              fontSize={13}
                              fontWeight={500}
                              color={"gray"}
                            >
                              No specialties yet!
                            </Typography>
                          ) : (
                            agent?.specialties.map((a, index) => (
                              <Box key={a.title}>
                                <Typography
                                  fontSize={13}
                                  fontWeight={500}
                                  color={"gray"}
                                >
                                  {a.title}
                                  {index < agent.specialties.length - 1 && ","}
                                </Typography>
                              </Box>
                            ))
                          )}
                        </Box>
                      </Box>
                    </div>
                    <Box
                      sx={{
                        display: "absolute",
                        bottom: 10,
                      }}
                    >
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          agent?.web_link
                            ? window.open(`${agent?.web_link}`, "_blank")
                            : window.open(
                              `https://bpotech.io/order-bpo-report/${agent?.id}`,
                              "_blank"
                            )
                        }
                        color="secondary"
                      >
                        Visit my website
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Grid>
            <Grid item sm={10} md={4} width={"100%"}>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  position: "relative",
                  padding: 4,
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${sliderBg})`,

                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    opacity: 1,
                  },
                  height: "29vh",
                  mb: isMobile && 2,
                }}
              >
                <Carousel duration={2000} indicators={false}>
                  <>
                    <Typography
                      sx={{ zIndex: 4, color: "white", fontWeight: 500 }}
                      fontSize={30}
                    >
                      Need a BPO Report?
                    </Typography>
                    <Typography
                      sx={{ zIndex: 4, color: "white", fontWeight: 300, my: 4 }}
                    >
                      BPO reports offer an informal estimation of your
                      property's value for selling, buying, financing, or other
                      needs.
                    </Typography>
                    <Button
                      href={`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}order-bpo-report`}
                      variant="contained"
                      sx={{
                        bgcolor: "white",
                        maxWidth: "12rem",
                        textTransform: "none",
                      }}
                    >
                      Order BPO Report
                    </Button>
                  </>
                  <>
                    <Typography
                      sx={{ zIndex: 4, color: "white", fontWeight: 500 }}
                      fontSize={30}
                    >
                      Need a Proforma Report?
                    </Typography>
                    <Typography
                      sx={{ zIndex: 4, color: "white", fontWeight: 300, my: 4 }}
                    >
                      BPO reports offer an informal estimation of your
                      property's value for selling, buying, financing, or other
                      needs.
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "white",
                        minWidth: "12rem",
                        textTransform: "none",
                      }}
                    >
                      Order BPO Report
                    </Button>
                  </>
                </Carousel>
              </Container>
            </Grid>
          </Grid>{" "}
        </Container>
      </Box>
      <Container
        maxWidth={"xl"}
        sx={{
          minHeight: isMobile ? "120vh" : "50vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: isMobile ? "start" : "center",
        }}
      >
        <Grid container>
          <Grid item sm={10} height={isMobile && "30vh"} md={3}>
            <Box>
              <Typography fontSize={"2rem"} fontWeight={"700"}>
                Need an Industry
                <br /> professional?
              </Typography>
            </Box>
            <Box
              sx={{
                width: 100,

                bgcolor: theme.palette.primary.main,
                mt: isMobile ? 0 : 5,
              }}
            />

            <Typography mt={1} mb={8}>
              Attorney, Contractors, Stagers,
              <br />
              Electricians, Plumbers, More!
            </Typography>
            <Button
              sx={{
                transition: "1s ease-in-out",
                textTransform: "none",
                height: 45,
                color: "white",
              }}
              href={`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}industry-professionals`}
              className="btn-grad"
            >
              {" "}
              View more!
              <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid
            item
            sm={10}
            md={9}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mt: isMobile ? 3 : null,
              gap: 4,

              width: "100%",
              height: "40vh",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "48%",
                position: "relative",
                height: "100%",
              }}
            >
              <Carousel
                duration={3000}
                sx={{ height: "100%", position: "relative" }}
              >
                {homeScript?.map(({ body, title, image, link }) => (
                  <Card
                    key={title}
                    sx={{
                      position: "absolute",
                      height: isMobile ? "90%" : "100%",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${image})`,
                        backgroundSize: isMobile ? "100% 100%" : "100% 100%",
                        backgroundRepeat: "no-repeat",
                        opacity: 1,
                      },
                    }}
                  >
                    <CardContent
                      className="home-card"
                      sx={{
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: 400,
                          mb: 3,
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        className="reveal-description-text"
                        color={"white"}
                        sx={{ padding: 1, fontSize: 14 }}
                      >
                        {body}
                      </Typography>

                      <Button
                        variant="outlined"
                        sx={{
                          color: "white",
                          textTransform: "none",
                          border: "block",
                          borderColor: "white",
                          px: 5,
                        }}
                        target="_blank"
                        href={`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}industry-professionals`}
                      >
                        View List
                      </Button>
                    </CardContent>
                  </Card>
                ))}
              </Carousel>
            </Box>
            <Paper
              sx={{
                width: isMobile ? "100%" : "48%",
                position: "relative",
                height: "100%",
              }}
            >
              <Carousel
                duration={3000}
                sx={{ height: "100%", position: "relative" }}
              >
                {homeScript?.map(({ body, title, image, link }) => (
                  <Card
                    key={title}
                    sx={{
                      position: "absolute",
                      height: "100%",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <img
                      style={{ position: "absolute", right: 0 }}
                      src={star}
                      alt="star"
                    />
                    <CardContent
                      sx={{
                        height: "100%",
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                        p: 4,
                      }}
                    >
                      <Typography fontWeight={700}>{title}</Typography>
                      <Typography fontWeight={300} mb={2}>
                        {title}
                      </Typography>
                      <Typography fontWeight={300} mb={2}>
                        {body}
                      </Typography>
                      <Typography mt={4}>
                        <Rating
                          color="primary"
                          size="small"
                          value={5}
                          readOnly
                          precision={0.5}
                        />
                        300 Reviews
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Carousel>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${peopleTalking})`,
            backgroundSize: isMobile ? "100% 100%" : "100% 220%",
            backgroundPositionY: "50%",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: -2,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: -1,
          },
          height: "50vh",
        }}
      >
        <Typography
          color={"primary"}
          sx={{ fontWeight: 700, fontSize: "3rem", textAlign: "center" }}
        >
          Join <br />
          BPO Homes Realty
        </Typography>
        <Typography
          color="white"
          sx={{ fontWeight: 400, textAlign: "center", my: "2rem" }}
        >
          Flat Fees Commission Rates Starting At Only <br />
          $199.00 Per Transaction
        </Typography>
        <Button
          onClick={(e) => routeToPage(e, "/agentComp")}
          variant="contained"
          sx={{ color: "white", width: "15rem" }}
          endIcon={<ArrowForwardIcon />}
        >
          {" "}
          Join Now
        </Button>
      </Box>
      <Box
        sx={{
          mt: 10,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${newBg})`,
            backgroundSize: isMobile ? "100% 100%" : "100% 200%",
            backgroundPositionY: "50%",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: -2,
          },
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: -1,
          },
          height: "60vh",
        }}
      >
        <Typography
          color={"primary"}
          sx={{ fontWeight: 700, fontSize: "3rem", textAlign: "center" }}
        >
          Get your
          <br />
          Real Estate License
        </Typography>
        <Typography
          color="white"
          sx={{ fontWeight: 400, textAlign: "center", my: "2rem" }}
        >
          Join our Internship Program
        </Typography>
        <Button
          onClick={(e) => routeToPage(e, "/brokerage")}
          variant="contained"
          sx={{ color: "white", width: "15rem" }}
          endIcon={<ArrowForwardIcon />}
        >
          Join Now
        </Button>
      </Box>
    </Box>
  );
};

const homeScript = [
  {
    image: buyingCarousel,
    title: "Home Improvement",
    body: "Are you dreaming of a stunning home makeover? Look no further! Our team of exceptional home improvement professionals is here to bring your vision to life. Be ready to experience the ultimate transformation and create a space that reflects your unique style and personality.",
    buttonText: "View List",
    link: "/blog/home-improvement",
  },
  {
    image: homeBg2,
    title: "Property Managers",
    body: "Your trusted guardians, ensure your properties are well-maintained, secure, and thriving. From tenant screenings to rent collection, they handle the nitty-gritty details, allowing you to sit back and relax.",
    buttonText: "View List",
    link: "#",
  },
  {
    image: homeBg3,
    title: "Builders",
    body: "Join us on a journey where your dreams take shape. Our dedicated team of professionals is ready to guide you through the entire construction process, from conception to completion.",
    buttonText: "View List",
    link: "#",
  },
  {
    image: homeBg4,
    title: "Property Inspectors",
    body: "When it comes to choosing a Property Inspector, don't settle for anything less than excellence. Look for our qualified professionals who hold certifications, possess years of experience, and have a solid track record of client satisfaction. Your dream home deserves the utmost care and attention!",
    buttonText: "View List",
    link: "#",
  },
];
